import axios from "axios";
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import ProjectDashboard from "../components/projectDashboard.js"
import UserDashboard from "../components/userDashboard.js"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SideNav from './side-nav';
import SideNavAdmin from './side-nav-admin';
import { TopNav } from './top-nav';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import NewProjectDialog from "../dialog/new-project-dialog.js"
import NewDataDialog from "../dialog/new-data-dialog.js"
import EditProjectDialog from "../dialog/edit-project-dialog.js"
import DeleteProjectDialog from "../dialog/delete-project-dialog.js"

import UploadQueue from '../components/uploadQueue';

const SIDE_NAV_WIDTH = 280;
const SIDE_NAV_ADMIN_WIDTH = 340;

function Main() {

    const { user,
        signOut,
        accessToken,
        idToken,
        setJwt,
        jwt,
        usage, setUsage,
        maxSpace, setMaxSpace,
        setMaxFileSize,
        setDemo,
        setRegion,
        region,
        setIdpool,
        idpool,
        adminMode,
        setIsAdmin,
        setCloudinary_cloud,
        cloudinary_cloud,
        setCloudinary_api_key,
        setCloudinary_api_secret,
        setCloudinary_preset,
        cloudinary_preset
    } = useContext(AuthContext);

    const [language, setLanguage] = useState("jp");
    const { t } = useTranslation();

    const LayoutRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: SIDE_NAV_WIDTH
        }
    }));

    const LayoutAdminRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: SIDE_NAV_ADMIN_WIDTH
        }
    }));

    const LayoutContainer = styled('div')({
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%'
    });

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('languageMirukuru')) {
            setLanguage(localStorage.getItem('languageMirukuru'));
        }

        if (user) {
            //setLoadingData(true);
            if (adminMode) {
                console.log("Admin Mode On");
                retrieveUsers(); // Ensure users are fetched when adminMode is true
            } else {
                retrieveProjects();
            }
        } else {
            console.log("Signout useeffect");
            signOut().then(() => {
                navigate('/login');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, adminMode]);

    //const [projectSelectedId, setProjectSelectedId] = useState(null);
    const [userHasNoProject, setUserHasNoProject] = useState(true);

    async function getStrapiUser() {
        let jwtStrapi = "";
        let userStrapi = {};
        try {
            let request = `${global.API_URL}/api/auth/cognito/callback?id_token=${idToken}&access_token=${accessToken}`;
            let res = await axios.get(request);
            jwtStrapi = res.data.jwt;
            setJwt(jwtStrapi);
            userStrapi = await axios.get(`${global.API_URL}/api/users/me?populate=*`, {
                headers: {
                    Authorization:
                        `Bearer ${jwtStrapi}`,
                },
            });
            setUserAuth(userStrapi.data);
            setMaxSpace(userStrapi.data.space);
            setMaxFileSize(userStrapi.data.maxFileSize);
            setDemo(userStrapi.data.demo);
            setIsAdmin(userStrapi.data.admin)
            getCredentials(jwtStrapi);
        } catch {
            console.log("cannot retrieve strapi user");
            signOut().then(res => {
                let path = `/login`;
                navigate(path);
            })
        }
        return {
            jwtStrapi: jwtStrapi,
            userStrapi: userStrapi
        };
    }

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    async function retrieveUsers() {
        setLoading(true);
        //console.log("Retrieving users in admin mode..."); // Add this line

        const { jwtStrapi } = await getStrapiUser();
        let res = await axios.get(`${global.API_URL}/api/users?populate[projects][populate][0]=data`, {
            headers: {
                Authorization: `Bearer ${jwtStrapi}`,
            },
        });

        let users = res.data;
        users.sort(function (a, b) {
            var textA = a.email.toUpperCase();
            var textB = b.email.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        console.log("Fetched users:", users); // Log the retrieved users
        setUsers(users);

        if (!userSelected) {
            setUserSelected(users[0]);
        } else {
            let newUserSelected = users.find(item => item.id === userSelected.id);
            setUserSelected(newUserSelected);
        }
        setLoading(false);
    }

    async function retrieveProjects() {
        if (loading) return; // Prevent multiple simultaneous calls
        //console.log("retrieveProjects"); 
        setLoading(true);
        try {
            const { jwtStrapi, userStrapi } = await getStrapiUser();
            let ids = [];
            let userProjects = [];

            if (userStrapi.data.projects !== undefined) {
                ids = userStrapi.data.projects.map(project => project.id);

                const res = await axios.get(`${global.API_URL}/api/projects?populate[data][populate][0]=results`, {
                    headers: {
                        Authorization: `Bearer ${jwtStrapi}`,
                    },
                });

                userProjects = res.data.data.filter(proj => ids.includes(proj.id));
            }

            userProjects.sort(function (a, b) {
                var textA = a.attributes.title.toUpperCase();
                var textB = b.attributes.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            setProjects(userProjects);
            setUsage(getSpaceUsage(userProjects));

            if (userProjects.length > 0) {
                setUserHasNoProject(false);
            } else {
                setUserHasNoProject(true);
            }
            setProjectSelected(userProjects[0] || null);
        } catch (error) {
            console.error("Failed to retrieve projects", error);
        } finally {
            setLoading(false);
        }
    }

    async function getCredentials(jwt) {
        let res = await axios.get(`${global.API_URL}/api/credentials`, {
            headers: {
                Authorization:
                    `Bearer ${jwt}`,
            },
        });
        let response = res.data.data[0].attributes;
        setRegion(response.region);
        setIdpool(response.identity_pool);
        setCloudinary_cloud(response.cloudinary_cloud);
        setCloudinary_api_key(response.cloudinary_api_key);
        setCloudinary_api_secret(response.cloudinary_api_secret);
        setCloudinary_preset(response.cloudinary_preset);
    }

    function getSpaceUsage(projects) {
        let space = 0;
        for (let i = 0; i < projects.length; i += 1) {
            let proj = projects[i].attributes;
            for (let j = 0; j < proj.data.data.length; j += 1) {
                let d = proj.data.data[j];
                space += parseFloat(d.attributes.size);
            }
        }
        return space / 1e9;
    }

    const [projects, setProjects] = useState([]);
    const [userAuth, setUserAuth] = useState({});
    const [projectSelected, setProjectSelected] = useState(null);
    const [openNav, setOpenNav] = useState(false);
    const [openDialogProject, setOpenDialogProject] = useState(false);
    const [openDialogData, setOpenDialogData] = useState(false);
    const [openDialogEditProject, setOpenDialogEditProject] = useState(false);
    const [openDialogDeleteProject, setOpenDialogDeleteProject] = useState(false);

    const handleNewProject = () => {
        setOpenDialogProject(true);
        setOpenNav(false);
    };

    const handleDeleteProject = () => {
        setOpenDialogDeleteProject(true);
        setOpenNav(false);
    };

    const handleEditProject = () => {
        setOpenDialogEditProject(true);
        setOpenNav(false);
    };

    const handleNewData = () => {
        setOpenDialogData(true);
    };

    const callBackGetProjects = () => {
        //console.log("callBackGetProjects")
        retrieveProjects();
    };

    const callBackGetUsers = () => {
        retrieveUsers();
    };

    return (
        <div className="Main">

            <>
                {adminMode ?
                    <>
                        <TopNav onNavOpen={() => setOpenNav(true)} language={language} setLanguage={setLanguage} projects={projects} loading={loading} />
                        <SideNavAdmin onClose={() => setOpenNav(false)} open={openNav} users={users} userSelected={userSelected} setUserSelected={setUserSelected} />
                        <LayoutAdminRoot>
                            <LayoutContainer>
                                <Box sx={{ flexGrow: 1, margin: 1 }}>
                                    <UserDashboard
                                        userSelected={userSelected} reloadUsers={callBackGetUsers}
                                    />
                                </Box>
                            </LayoutContainer>
                        </LayoutAdminRoot>
                    </>
                    :
                    <>
                        <TopNav onNavOpen={() => setOpenNav(true)} language={language} setLanguage={setLanguage} projects={projects} loading={loading} />
                        <SideNav onClose={() => setOpenNav(false)} open={openNav} userHasNoProject={userHasNoProject} handleClickOnNewProject={handleNewProject} projects={projects} projectSelected={projectSelected} setProjectSelected={setProjectSelected} usage={usage} maxSpace={maxSpace}
                        />
                        {(!userHasNoProject) ?
                            <>
                                <LayoutRoot>
                                    <LayoutContainer>
                                        <Box sx={{ flexGrow: 1, margin: 1 }}>
                                            <ProjectDashboard
                                                projectSelected={projectSelected}
                                                handleClickOnNewData={handleNewData}
                                                handleClickOnEditProject={handleEditProject}
                                                handleClickOnDeleteProject={handleDeleteProject}
                                                reloadProjects={callBackGetProjects}
                                            />
                                        </Box>
                                    </LayoutContainer>
                                </LayoutRoot>

                                <UploadQueue
                                    user={user}
                                    idToken={idToken}
                                    jwt={jwt}
                                    region={region}
                                    idpool={idpool}
                                    cloudinary_cloud={cloudinary_cloud}
                                    cloudinary_preset={cloudinary_preset}
                                    projectSelected={projectSelected}
                                    reloadProjects={callBackGetProjects}
                                    userId={userAuth.id}
                                    t={t}
                                >
                                    {({ addToUploadQueue }) => (
                                        <>
                                            {/* Pass addToUploadQueue to NewDataDialog */}
                                            <NewDataDialog
                                                open={openDialogData}
                                                setOpen={setOpenDialogData}
                                                userId={userAuth.id}
                                                projectSelected={projectSelected}
                                                reloadProjects={callBackGetProjects}
                                                addToUploadQueue={addToUploadQueue}
                                            />
                                        </>
                                    )}
                                </UploadQueue>
                                <EditProjectDialog open={openDialogEditProject} setOpen={setOpenDialogEditProject} projectSelected={projectSelected} reloadProjects={callBackGetProjects} />
                                <DeleteProjectDialog open={openDialogDeleteProject} setOpen={setOpenDialogDeleteProject} projectSelected={projectSelected} reloadProjects={callBackGetProjects} />
                            </> :
                            <LayoutRoot>
                                <LayoutContainer>
                                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                                        <Stack spacing={3} alignItems="center">
                                            <Typography variant="h6">
                                                {t("Welcome")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("PleaseStart")}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </LayoutContainer>
                            </LayoutRoot>
                        }
                        <NewProjectDialog open={openDialogProject} setOpen={setOpenDialogProject} userId={userAuth.id} handleNewProjectCreated={callBackGetProjects} />
                    </>
                }</>
        </div>
    );
}

export default Main;